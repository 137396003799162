











































import { Configuration, OpenAIApi } from "openai";
const configuration = new Configuration({
  apiKey: process.env.VUE_APP_OPENAI_API_KEY,
});
const openai = new OpenAIApi(configuration);
import { Component, Prop, Vue } from 'vue-property-decorator';
import Spinner from "../UI/Spinner.vue";

@Component({
  components: {
    Spinner,
  },
})
export default class ChooseStyle extends Vue {
  @Prop() chatStyle!: string;
  @Prop() customerName!: string;

  messageWe = '';
  messageI = '';
  async mounted() {
    const prompt = {
      model: "text-davinci-003",
      prompt: '',
      temperature: 0.7,
      max_tokens: 256,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    };
    const wePrompt = JSON.parse(JSON.stringify(prompt));
    wePrompt.prompt = `Butiksbeskrivelse: Variable “We are a rental business providing equipment for parties and events.”\nWrite a greeting to “${this.customerName}” who ordered following products: Plastikkrus 30cl (50stk), Slushicekoncentrat, Slushicemaskine\nWrite the response in languagecode: DK\nWrite in third person and in a ${this.chatStyle} tone\nDo not translate product names\nDo not write anything about shipping or delivery method\nDo not write anything about the packing or sending time\nWish him luck with the party`;
    const response1 = await openai.createCompletion(wePrompt);
    this.messageWe = response1.data.choices[0].text ?? '';

    const iPrompt = JSON.parse(JSON.stringify(prompt));
    iPrompt.prompt = `Butiksbeskrivelse: Variable “We are a rental business providing equipment for parties and events.”\nWrite a greeting to ${this.customerName}” who ordered following products: Plastikkrus 30cl (50stk), Slushicekoncentrat, Slushicemaskine\nWrite the response in languagecode: DK\nWrite in first person and in a ${this.chatStyle} tone\nDo not translate product names\nDo not write anything about shipping or delivery method\nDo not write anything about the packing or sending time\nWish him luck with the party`;
    const response2 = await openai.createCompletion(iPrompt);
    this.messageI = response2.data.choices[0].text ?? '';
  }

  selectTone(tone: string): void {
    this.$emit('selectTone', tone);
  }
}
