


























































import { Configuration, OpenAIApi } from "openai";
const configuration = new Configuration({
  apiKey: process.env.VUE_APP_OPENAI_API_KEY,
});
const openai = new OpenAIApi(configuration);
import { Component, Prop, Vue } from 'vue-property-decorator';
import Spinner from "../UI/Spinner.vue";

const tone = {
  "CONVERSATIONAL": "CONVERSATIONAL",
  "PROFESSIONAL": "PROFESSIONAL",
  "CASUAL": "CASUAL",
}

@Component({
  components: {
    Spinner,
  },
})
export default class ChooseStyle extends Vue {
  @Prop() customerName!: string;

  messageConversasional = '';
  messageProfessional = '';
  messageCasual = '';
  async mounted() {
    const prompt = {
      model: "text-davinci-003",
      prompt: '',
      temperature: 0.7,
      max_tokens: 256,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    };
    const conversationalPrompt = JSON.parse(JSON.stringify(prompt));
    conversationalPrompt.prompt = `Butiksbeskrivelse: “We are a rental business providing equipment for parties and events.”\nWrite a greeting to “${this.customerName}” who ordered following products: Plastikkrus 30cl (50stk), Slushicekoncentrat, Slushicemaskine\nWrite the response in languagecode: EN\nWrite in first person and in a ${tone.CONVERSATIONAL} tone\nDo not translate product names\nDo not write anything about shipping or delivery method\nDo not write anything about the packing or sending time\nWish him luck with the party`;
    const response1 = await openai.createCompletion(conversationalPrompt);
    this.messageConversasional = response1.data.choices[0].text ?? '';

    const professionalPrompt = JSON.parse(JSON.stringify(prompt));
    professionalPrompt.prompt = `Butiksbeskrivelse: “We are a rental business providing equipment for parties and events.”\nWrite a greeting to "${this.customerName}” who ordered following products: Plastikkrus 30cl (50stk), Slushicekoncentrat, Slushicemaskine\nWrite the response in languagecode: EN\nWrite in first person and in a ${tone.PROFESSIONAL} tone\nDo not translate product names\nDo not write anything about shipping or delivery method\nDo not write anything about the packing or sending time\nWish him luck with the party`;
    const response2 = await openai.createCompletion(professionalPrompt);
    this.messageProfessional = response2.data.choices[0].text ?? '';

    const casualPrompt = JSON.parse(JSON.stringify(prompt));
    casualPrompt.prompt = `Butiksbeskrivelse: “We are a rental business providing equipment for parties and events.”\nWrite a greeting to “${this.customerName}” who ordered following products: Plastikkrus 30cl (50stk), Slushicekoncentrat, Slushicemaskine\nWrite the response in languagecode: EN\nWrite in first person and in a ${tone.CASUAL} tone\nDo not translate product names\nDo not write anything about shipping or delivery method\nDo not write anything about the packing or sending time\nWish him luck with the party`;
    const response3 = await openai.createCompletion(casualPrompt);
    this.messageCasual = response3.data.choices[0].text ?? '';
  }

  selectStyle(style: string): void {
    console.log("selectStyle " + style);
    this.$emit('selectStyle', style);
  }
}
