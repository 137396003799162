<template>
  <div class="fadeInUp">
    <div class="mb-6">
      <h1 class="h2 font-weight-bold">
        Create account
      </h1>
    </div>

    <form class="form-row" @submit.prevent="register()">
      <div
        class="form-group col-12 required"
        :class="{ 'is-invalid': errors.has('title') }"
      >
        <label for="title" class="control-label text-capitalize">
          Organization
        </label>
        <input
          id="title"
          v-model="user.title"
          v-validate="{ required: true }"
          type="text"
          name="title"
          placeholder="Pied Piper"
          class="form-control form-control-lg"
        />
        <span v-show="errors.has('title')" class="invalid-feedback">{{
          errors.first("title")
        }}</span>
      </div>
      <div
        class="form-group col-12 required"
        :class="{ 'is-invalid': errors.has('domain') }"
      >
        <label for="domain" class="control-label text-capitalize">
          Shop domain
        </label>
        <input
          id="domain"
          v-model="user.domain"
          v-validate="{ required: true }"
          type="text"
          name="domain"
          placeholder="automation.app"
          class="form-control form-control-lg"
          :diabled="domainValidated"
        />
        <span v-show="errors.has('domain')" class="invalid-feedback">{{
          errors.first("domain")
        }}</span>
      </div>
      <div
        class="form-group col-12 required"
        :class="{ 'is-invalid': errors.has('country') }"
      >
        <label for="country" class="control-label text-capitalize">
          Country
        </label>
        <multiselect
          id="country"
          v-model="user.country"
          v-validate="{ required: true }"
          name="country"
          :options="countries"
          label="name"
          track-by="code"
          :show-labels="false"
        />
        <span v-show="errors.has('country')" class="invalid-feedback">{{
          errors.first("country")
        }}</span>
        <hr class="border border-lightdark mt-6" />
      </div>
      <div
        class="form-group col-12 required"
        :class="{ 'is-invalid': errors.has('name') }"
      >
        <label for="name" class="control-label text-capitalize">Name</label>
        <input
          id="name"
          v-model="user.name"
          v-validate="{ required: true }"
          type="text"
          class="form-control"
          placeholder="Shop owner"
          name="name"
          autocomplete="name"
        />
        <span v-show="errors.has('name')" class="invalid-feedback">{{
          errors.first("name")
        }}</span>
      </div>
      <div
        class="form-group col-12 required"
        :class="{ 'is-invalid': errors.has('email') }"
      >
        <label for="email" class="control-label text-capitalize">Email</label>
        <input
          id="email"
          v-model="user.email"
          v-validate="{ required: true }"
          type="email"
          class="form-control"
          placeholder="owner@automation.app"
          name="email"
          autocomplete="email"
        />
        <span v-show="errors.has('email')" class="invalid-feedback">{{
          errors.first("email")
        }}</span>
      </div>
      <div
        class="form-group col-12 col-md-6 required"
        :class="{ 'is-invalid': errors.has('password') }"
      >
        <label for="password" class="control-label text-capitalize"
          >Password</label
        >
        <input
          id="password"
          v-model="user.password"
          v-validate="{ required: true }"
          type="password"
          placeholder="*****"
          class="form-control mb-2"
          name="password"
          autocomplete="new-password"
        />
        <span v-show="errors.has('password')" class="invalid-feedback">{{
          errors.first("password")
        }}</span>
      </div>
      <div
        class="form-group col-12 col-md-6"
        :class="{ 'is-invalid': errors.has('password_confirmation') }"
      >
        <label for="password-confirm" class="control-label text-capitalize"
          >Confirm password</label
        >
        <input
          id="password-confirm"
          v-model="user.password_confirmation"
          v-validate="{ required: true }"
          type="password"
          class="form-control mb-2"
          placeholder="*****"
          name="password_confirmation"
          autocomplete="new-password"
          @keyup.enter="register()"
        />
        <span
          v-show="errors.has('password_confirmation')"
          class="invalid-feedback"
          >{{ errors.first("password_confirmation") }}</span
        >
      </div>
      <div class="form-group col-12">
        <ButtonLoading
          :loading="loading"
          :title="'Sign up'"
          :class="'btn btn-primary btn-block'"
        />
      </div>
    </form>

    <!--    <div class="py-5 text-center">-->
    <!--      <p class="text-muted small mb-0">-->
    <!--        or sign up with a social provider-->
    <!--      </p>-->
    <!--    </div>-->

    <!--    <oauth-button-->
    <!--      :provider="'github'"-->
    <!--      :login="false"-->
    <!--      @successfulAuth="proceed"-->
    <!--    />-->
    <!--    <oauth-button-->
    <!--      :provider="'google'"-->
    <!--      :login="false"-->
    <!--      @successfulAuth="proceed"-->
    <!--    />-->
    <!--    <oauth-button-->
    <!--      :provider="'facebook'"-->
    <!--      :login="false"-->
    <!--      @successfulAuth="proceed"-->
    <!--    />-->
  </div>
</template>

<script>
import jsonAPI from "@/api";
import Multiselect from "vue-multiselect";
import countries from "@/helpers/countries";
import ButtonLoading from "../Form/ButtonLoading.vue";

export default {
  name: "CreateAccount",
  components: {
    Multiselect,
    ButtonLoading,
  },
  data() {
    return {
      step: 0,
      loading: false,
      user: {
        title: null,
        domain: null,
        country: null,
        name: null,
        email: null,
        password: null,
        password_confirmation: null,
      },
      organization: {
        title: null,
        domain: null,
        country: null,
      },
      domainValidated: false,
      domainCheck: false,
      countries: countries,
      utm_params: {
        utm_source: localStorage.getItem("utm_source"),
        utm_medium: localStorage.getItem("utm_medium"),
        utm_campaign: localStorage.getItem("utm_campaign"),
        keyword: localStorage.getItem("keyword"),
        utm_content: localStorage.getItem("utm_content"),
      },
    };
  },
  computed: {
    domainValid() {
      return this.domainValidated;
    },
    testButtonName() {
      return this.domainCheck ? "Try again" : "Test domain";
    },
    showInvalidDomain() {
      return !this.domainValidated && this.domainCheck;
    },
  },
  methods: {
    register() {
      this.loading = true;
      this.$validator.validateAll(this.user).then((result) => {
        if (result) {
          this.user.domain = this.user.domain?.replace("https://", "");
          this.user.domain = this.user.domain?.replace("http://", "");
          if (this.utm_params.utm_source) {
            this.user.settings = {};
            this.user.settings.utm_params = this.utm_params;
          }
          if (this.user.country) {
            this.user.country = this.user.country.code;
          }
          jsonAPI
            .post("/register", this.user)
            .then(async ({ data }) => {
              await this.$store.dispatch("ACTIVATE_ONBOARDING");
              await this.$store.dispatch("SET_TOKEN", data.token);
              await this.$store.dispatch("LOGIN", data.user);
              await this.$store.dispatch("SET_PERMISSIONS", data.user.permissions);

              const userName = this.user.name.split(" ");
              const lastName = userName.splice(-1, 1);
              const firstName = userName.join(" ");
              let _data = {
                email: this.user.email,
                lastname: lastName[0],
                website: this.user.domain,
                company: this.user.title,
                country: this.user.country,
              };
              if (firstName) {
                _data.firstname = firstName;
              }
              jsonAPI.post("/marketing/createContact", _data);
              jsonAPI.post("/marketing/event", {
                action: "organisation-created",
                email: this.user.email,
              });
              this.$emit("proceed", {
                name: this.user.name,
                company: this.user.title,
                domain: this.user.domain,
                country: this.user.country,
              });
            })
            .catch((error) => {
              this.displayErrors(error);
            });
        }
        this.loading = false;
      });
    },
    displayErrors(error) {
      Object.keys(error.response.data.errors).forEach((key) => {
        this.$validator.errors.add({
          field: key,
          msg: error.response.data.errors[key][0],
        });
      });
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
