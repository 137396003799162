



































































import Vue from "vue";
import { AxiosResponse } from "axios";
import jsonAPI from "@/api";
import Logo from "@/components/Svg/Logo.vue";
import CreateAccount from "@/components/Account/CreateAccount.vue";
import DownloadPlugin from "@/components/Account/DownloadPlugin.vue";
import ChooseStyle from '@/components/Account/ChooseStyle.vue';
import ChooseTone from '@/components/Account/ChooseTone.vue';
import ConnectStore from '@/components/Onboarding/ConnectStore.vue';

import { Organization } from "@/types/organization";
import utilities from "@/helpers/utilities";

interface RegisterResponse {
  organization: Organization;
  permissions: string[];
}

export default Vue.extend({
  name: "Register",
  components: {
    CreateAccount,
    DownloadPlugin,
    ChooseStyle,
    ChooseTone,
    ConnectStore,
    Logo,
  },
  data() {
    return {
      step: 0,
      loading: false,
      user: {
        name: null,
        email: null,
        password: null,
        password_confirmation: null,
        terms: false,
      },
      organization: {
        title: null,
        domain: null,
        country: null,
      },
      test: null,
      chatGpt: {
        style: '',
        tone: '',
      }
    };
  },
  async created() {
    this.$store.commit("activateOnboarding", true);
    if (this.$route.query.step && this.$data.isLogged) {
      try {
        const response: AxiosResponse = await jsonAPI.get("user/current");
        const data = response.data as RegisterResponse;
        this.$data.organization = data.organization;
      } catch (err) {
        this.$store.dispatch("LOGOUT");
      }

      this.$data.step = this.$route.query.step;
    }
  },
  methods: {
    organizationCreated(customerInfo) {
      this.$data.user.name = customerInfo.name;
      this.$data.organization.title = customerInfo.title;
      this.$data.organization.domain = customerInfo.domain;
      this.$data.organization.country = customerInfo.country;
      this.proceed();
    },
    proceed() {
      console.log("Lets proceed")
      this.loading = false;
      this.step++;
      this.$router
        .replace({ query: { step: this.$data.step } })
        .catch((err) => {
          console.log(err);
        });
    },
    connectStore() {
      utilities.openWindow(
        "https://" + this.organization.domain + "/wp-admin/plugin-install.php",
        "Login",
        { width: 800, height: 720 }
      );
      this.registerFinished();
    },
    registerFinished() {
      this.$router.push({ name: "overview" });
      this.$store.commit("activateOnboarding", false);
    },
    async storeConnected() {
      const { data } = await jsonAPI.post(
        `/system/connect/WooCommerce`
      );
      console.log(data)
      this.proceed();
    },
    styleSelected(style) {
      this.chatGpt.style = style;
      this.proceed();
    },
    toneSelected(tone) {
      this.chatGpt.tone = tone;
      jsonAPI.post(`/system/chatGpt`, this.chatGpt);
      this.proceed();
    },
  },
});
